<script>
import { CountTo } from "vue3-count-to";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";

import ImmigrationRequestServices from "../../services/ImmigrationRequestServices";

import Swal from "sweetalert2";

export default {
  page: {
    title: "Détails de la souscription",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      isCreateTxModalOpen: false,
      fields: {
        amount: "",
        reason: "",
        channel: "cash",
        paid_at: "",
      },
      title: "Informations sur la requête d'immigration",
      date3: null,
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      date: null,
      allTransactions: [],
      searchQuery: null,
      page: 1,
      perPage: 8,
      pages: [],
      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      currentSubscription: { steps: [] },
    };
  },
  components: {
    CountTo,
    Layout,
    PageHeader,
    lottie: Lottie,
    // Multiselect,
    flatPickr,
  },
  computed: {
    items() {
      return [
        {
          text: "Requêtes d'immigration",
          href: "/immigration-requests",
        },
        {
          text: "Détails de la souscrition",
          href:
            "/immigration-requests/" + this.currentSubscription.id + "/view",
        },
        {
          text: "Informations sur l'étape",
          active: true,
        },
      ];
    },
    currentStep() {
      return (
        this.currentSubscription?.steps.find(
          (step) => step.id === Number(this.$route.params.stepId)
        ) || {}
      );
    },
    displayedPosts() {
      return this.paginate(this.allTransactions);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return data.reason.toLowerCase().includes(search);
        });
      } else if (this.filterdate !== null) {
        console.log(this.filterdate);
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.dueDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.dueDate.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          console.log(this.filtervalue);
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    allTransactions() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  beforeMount() {
    this.getCurrentSubscription();
  },

  methods: {
    openCreateTxModal() {
      this.isCreateTxModalOpen = true;
    },
    handleCreateTransaction() {
      this.$startLoading();
      ImmigrationRequestServices.createTransaction(
        this.$route.params.id,
        this.$route.params.stepId,
        this.fields
      )
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Votre transaction a été créée avec succès.");

            this.fields = {
              reason: "",
              channel: "cash",
              amount: null,
              paid_at: null,
            };
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la création de votre transaction.");
        })
        .finally(() => {
          this.getCurrentSubscription();
          // this.$stopLoading();
        });
    },
    getCurrentSubscription() {
      ImmigrationRequestServices.getById(this.$route.params.id)
        .then((data) => {
          this.currentSubscription = data.request;
          const tmpCurrentStep = data.request?.steps?.find(
            (step) => step.id === Number(this.$route.params.stepId)
          );
          // console.log(data.request.steps)
          this.allTransactions = [];
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          tmpCurrentStep.payments.forEach((row) => {
            var dd = new Date(row.created_at);
            row.dueDate =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();

            // row.image_src = `@/assets/images/products/img-8.png`;
            // row.image_src = 'https://api-node.themesbrand.website/fileupload/users_bucket/' + row.img;
            this.allTransactions.push(row);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    },
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allTransactions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(allTransactions) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return allTransactions.slice(from, to);
    },
    async handleDefinePrice($step_id) {
      Swal.fire({
        title: "Entrez le montant à attribuer à l'étape",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        showLoaderOnConfirm: true,
        preConfirm: async (amount) => {
          await ImmigrationRequestServices.defineStepPrice(
            this.currentSubscription.id,
            $step_id,
            { amount: Number(amount) }
          )
            .then(() => {
              this.$saSuccess("Le prix a été défini avec succès !");
              // Swal.showValidationMessage(`La validation du dossier`);
            })
            .catch(() => {
              Swal.showValidationMessage(
                `Erreur lors de la mise à jour de l'étape !`
              );
            })
            .finally(() => {
              // this.$stopLoading();
              this.getCurrentSubscription();
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url,
          });
        }
      });
    },
    async handleConfirmStep($step_id) {
      Swal.fire({
        title: "Êtes-vous sûr de confirmer la validation de l'étape en cours ?",
        // text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#f46a6a",
        // confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, confirmer !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();

          ImmigrationRequestServices.confirmStep(
            this.currentSubscription.id,
            $step_id
          )
            .then(() => {
              this.$saSuccess("Validation de l'étape effetuée !");
            })
            .catch(() => {
              this.$saError("Erreur lors de la validation de l'étape !");
            })
            .finally(() => {
              // this.$stopLoading();
              this.getCurrentSubscription();
            });
        }
      });
    },
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal
      v-model="isCreateTxModalOpen"
      hide-footer
      title="Ajout d'une transaction"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="amount-field" class="form-label"> Montant </label>
          <input
            type="text"
            id="amount-field"
            class="form-control"
            placeholder="Entrez le montant"
            required
            v-model="fields.amount"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label"> Description </label>
          <input
            type="text"
            id="email"
            class="form-control"
            placeholder="Description du paiement"
            required
            v-model="fields.reason"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label">
            Canal de paiement
          </label>
          <select class="form-select" v-model="fields.channel">
            <option value="cash">Paiement en espèce</option>
            <option value="momo">MTN Mobile Money</option>
            <option value="om">Orange Money</option>
            <option value="card">Carte</option>
            <option value="bank">Virement bancaire</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="paid_at" class="form-label">Payé le</label>
          <input
            type="datetime-local"
            id="paid_at"
            class="form-control"
            placeholder="Entrez la date de paiement"
            required
            v-model="fields.paid_at"
          />
        </div>
        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleCreateTransaction"
            >
              Enregistrer la transaction
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <router-link
      :to="{
        name: 'immigration-requests.details',
        params: { id: currentSubscription.id },
      }"
      class="btn btn-light mb-3"
    >
      &lt;-- Retour
    </router-link>

    <div class="row">
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Total à payer</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="currentStep.total_price"
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="ri-ticket-2-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Montant versé</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="currentStep.current_balance"
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Reste à verser</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="
                      currentStep.total_price - currentStep.current_balance
                    "
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">
                Transactions pour <b>"{{ currentStep.step_configs?.title }}"</b>
              </h5>
              <div class="flex-shrink-0">
                <button
                  class="btn btn-danger add-btn"
                  @click="openCreateTxModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Ajouter un
                  paiement
                </button>
              </div>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-12">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search bg-light border-light"
                      placeholder="Search for tasks or something..."
                      v-model="filtersearchQuery1"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-4 col-sm-4">
                  <flat-pickr
                    v-model="filterdate1"
                    placeholder="Selectionner la date"
                    :config="rangeDateconfig"
                    class="form-control"
                  ></flat-pickr>
                </div>
                <!--end col-->

                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="SearchData"
                  >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                    Filters
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <!--end card-body-->
          <div class="card-body">
            <div class="table-responsive table-card mb-4">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light text-muted">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Raison</th>
                    <!-- <th scope="col">Mois</th> -->
                    <th scope="col">Solde précédent</th>
                    <th scope="col">Nouveau solde</th>
                    <th scope="col">Créé le</th>
                  </tr>
                </thead>
                <tbody class="list form-check-all">
                  <tr v-for="(item, index) of resultQuery" :key="index">
                    <td>#{{ index + 1 }}</td>
                    <td>{{ item.amount }} FCFA</td>
                    <td>
                      {{ item.reason }}
                    </td>
                    <!-- <td>{{ item.month || "RAS" }}</td> -->
                    <td>{{ item.balance_before }} FCFA</td>
                    <td>{{ item.balance_after }} FCFA</td>
                    <td>{{ item.dueDate }}</td>
                  </tr>
                </tbody>
              </table>

              <!--end table-->
              <div class="noresult" style="display: none">
                <div class="text-center">
                  <lottie
                    colors="primary:#121331,secondary:#08a88a"
                    :options="defaultOptions"
                    :height="75"
                    :width="75"
                  />
                  <h5 class="mt-2">Sorry! No Result Found</h5>
                  <p class="text-muted mb-0">...</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <div class="pagination-wrap hstack gap-2">
                <a
                  class="page-item pagination-prev disabled"
                  href="#"
                  v-if="page != 1"
                  @click="page--"
                >
                  Précédent
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                    :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                    v-for="(pageNumber, index) in pages.slice(
                      page - 1,
                      page + 5
                    )"
                    :key="index"
                    @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                  class="page-item pagination-next"
                  href="#"
                  @click="page++"
                  v-if="page < pages.length"
                >
                  Suivant
                </a>
              </div>
            </div>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
